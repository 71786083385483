
.loading {
  @apply w-full flex items-center justify-center text-lg min-h-[250px] flex-col;

  &__slot {
    @apply flex-grow flex flex-col w-full min-h-0;
  }

  &.loading--blue-bg {
    .loading-text {
      @apply text-neutral-100;
    }

    .spinner {
      background: url('../../../assets/images/loaders/loading-spinner-white.svg') no-repeat;
    }
  }

  &.loading--small {
    @apply min-h-[100px];
  }

  &.loading--full {
    @apply h-full;
  }
}

.loading-text {
  @apply text-md text-center text-info-800 mt-[30px] font-medium;
}

.spinner {
  @apply h-[30px] w-[30px] animate-[rotate_1s_linear_infinite];

  background: url('../../../assets/images/loaders/loading-spinner.svg') no-repeat;
}

.loading--without-min-height {
  @apply min-h-0;
}
